import React from 'react';
import { useNavigate } from 'react-router-dom';

function NavItem({ member, isSelected }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (member.id) {
      navigate(`/${member.id}`);
    } else {
      navigate('/');
    }
  };

  return (
    <div
      className={`${member.id === '' ? 'logo-item' : ''} nav-item ${isSelected ? 'selected' : ''}`}
      style={{ backgroundImage: `url(${member.navImage})` }}
      onClick={handleClick}
    ></div>
  );
}

export default NavItem;
