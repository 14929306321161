import React from 'react';

function SocialIcons({ socials }) {
  if (!socials || socials.length === 0) return null;

  const getIconClass = (platform) => {
    switch (platform.toLowerCase()) {
      case 'twitter':
        return 'fab fa-twitter';
      case 'youtube':
        return 'fab fa-youtube';
      case 'instagram':
        return 'fab fa-instagram';
      case 'twitch':
        return 'fab fa-twitch';
      case 'discord':
        return 'fab fa-discord';
      case 'commission':
        return 'fas fa-paint-brush';
      case 'tiktok':
        return 'fab fa-tiktok';
      default:
        return 'fas fa-globe';
    }
  };

  const getPlatformName = (platform) => {
    switch (platform.toLowerCase()) {
      case 'twitter':
        return 'Twitter';
      case 'youtube':
        return 'YouTube';
      case 'instagram':
        return 'Instagram';
      case 'twitch':
        return 'Twitch';
      case 'discord':
        return 'Discord';
      case 'commission':
        return 'Commission';
      case 'tiktok':
        return 'TikTok';
      default:
        return 'Website';
    }
  };

  return (
    <div className="social-icons">
      {socials.map((social, index) => (
        <a
          key={index}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <i className={getIconClass(social.platform)}></i>
          <span>{getPlatformName(social.platform)}</span>
        </a>
      ))}
    </div>
  );
}

export default SocialIcons;
