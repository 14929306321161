import React from 'react';
import { useParams } from 'react-router-dom';
import members from '../data/members';
import MemberDisplay from './MemberDisplay';
import BottomNav from './BottomNav';

function App() {
    const { memberId } = useParams();
    const member = members.find(
        (m) => m.id.toLowerCase() === memberId?.toLowerCase()
    ) || members[0];
    
    return (
        <div className="container">
            <MemberDisplay member={member} />
            <BottomNav member={member} />
        </div>
    );
}

export default App;
