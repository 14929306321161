const members = [
    {
      id: '',
      name: '',
      bio: 'Negi no Tomodachi is an Indonesian virtual streamer group. We stream a variety of contents ranging from VR contents such as Beat Saber and VRChat, to utaite and competitive games. Join our Discord server to talk with us!',
      image: require('../assets/nnt-logo-display.webp'),
      navImage: require('../assets/nnt-logo-nav.webp'),
      socials: [
        { platform: 'discord', url: 'https://discord.nnt.gg' }
      ]
    },
    {
      id: 'ngeq',
      name: 'ngeQ',
      bio: 'Call me ngeQ or Negi! I stream Beat Saber on Twitch, though sometimes I stream other things xD. Sorry, I have no stream schedule yet, but usually I stream every Friday',
      image: require('../assets/ngeq-fb.webp'),
      navImage: require('../assets/ngeq-thumbnail.webp'),
      socials: [
        { platform: 'twitch', url: 'https://www.twitch.tv/ngeq' },
        { platform: 'youtube', url: 'https://www.youtube.com/@ngeQngeQ' },
        { platform: 'tiktok', url: 'https://www.tiktok.com/@ngeq' },
        { platform: 'twitter', url: 'https://x.com/ngeQ_' }
      ]
    },
    {
      id: 'tapiocaalice',
      name: 'tapiocaalice',
      bio: 'Halo, aku tapiocaalice🤍💙 can call me alice. Utaite from Indonesia (ID/JP/EN OK!). Youtube is the place where I sing, play games, and talk with you. Nice to meet you :)✨',
      image: require('../assets/tapiocaalice-fb.webp'),
      navImage: require('../assets/tapiocaalice-thumbnail.webp'),
      socials: [
        { platform: 'youtube', url: 'https://www.youtube.com/@tapiocaalice' },
        { platform: 'twitch', url: 'https://www.twitch.tv/tapioca_alice' },
        { platform: 'tiktok', url: 'https://www.tiktok.com/@tapiocaalice' },
        { platform: 'twitter', url: 'https://x.com/tapiocaalice' },
        { platform: 'commission', url: 'https://arisukaihen.carrd.co/' }
      ]
    },
    {
      id: 'mentega',
      name: 'Mentega',
      bio: 'Hewwo, I\'m Mentega Goreng! I usually stream VR games, especially Beat Saber; but sometimes I play normal 2D games, especially ones those \'rage-inducing\' games.',
      image: require('../assets/mentega-fb.webp'),
      navImage: require('../assets/mentega-thumbnail.webp'),
      socials: [
        { platform: 'twitch', url: 'https://www.twitch.tv/mentegagoreng' },
        { platform: 'youtube', url: 'https://www.youtube.com/@mentega' },
        { platform: 'tiktok', url: 'https://www.tiktok.com/@mentegago' },
        { platform: 'twitter', url: 'https://x.com/mentegasennn' }
      ]
    }
];

export default members;
  
