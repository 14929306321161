import React from 'react';
import NavItem from './NavItem';
import members from '../data/members';

function BottomNav({ member }) {
  return (
    <div className="bottom-nav">
      {members.map((m) => (
        <NavItem key={m.id} member={m} isSelected={m.id === member.id} />
      ))}
    </div>
  );
}

export default BottomNav;
