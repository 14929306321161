import React from 'react';
import SocialIcons from './SocialIcons';

function MemberDisplay({ member }) {

  return (
    <div className="member-display">
      <div
        className="member-image"
        style={{ backgroundImage: `url(${member.image})` }}
      ></div>
      <div className="member-description">
        <h1>{member.name || 'Negi no Tomodachi'}</h1>
        <p>{member.bio}</p>
        <SocialIcons socials={member.socials} />
      </div>
    </div>
  );
}

export default MemberDisplay;
